<template>
  <div class="mileage-statistics" ref="mileageStatistics">
    <el-tabs type="border-card" v-model="form.menuType" @tab-click="clickTab">
      <el-tab-pane label="统计" name="0">
        <div class="form-area" ref="form">
          <analysis-form
            @getFormVal="onSearch"
            :formatDay="true"
            :showCar="false"
            :requireCompany="true"
          >
            <el-button
              type="primary"
              size="small"
              :disabled="comArr.length === 0"
              :loading="downLoadStatus"
              @click="exportData(1)"
              >导出</el-button
            >
          </analysis-form>
        </div>
        <!-- 企业统计 -->
        <div class="form-table">
          <el-table :data="comArr" :height="tableHeight" stripe ref="comArrTable">
            <el-table-column
              type="index"
              label="序号"
            ></el-table-column>
            <el-table-column
              prop="statisticsDate"
              label="日期"
            >
            <template slot-scope="scope">{{
                scope.row.statisticsDate.substring(0,10)
              }}</template>
          </el-table-column>
            <el-table-column
              prop="companyName"
              label="所属企业"
            ></el-table-column>
            <el-table-column
              prop="vehicleNum"
              label="已安装ADAS车辆数"
            ></el-table-column>
            <el-table-column
              prop="useIcCardVehicleNum"
              label="启用IC卡车辆数"
            ></el-table-column>
            <el-table-column prop="completeRate" label="IC卡使用率">
              <template slot-scope="scope">{{
                filterRate(scope.row)
              }}</template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="详情" name="1">
        <div class="form-area">
          <analysis-form @getFormVal="onSubmit" :showMultDay="true" :isAllVehicle="true">
            <el-button
              type="primary"
              size="small"
              :disabled="carArr.length === 0"
              @click="exportData(2)"
              >导出</el-button
            >
          </analysis-form>
        </div>
        <!-- 车辆明细 -->
        <car-table
          ref="pagination2"
          :height="tableHeight - 30"
          :total="total"
          @sendInfo="sendInfo"
          :tableData="carArr"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import analysisForm from "@/components/analysisForm/analysisFormNew.vue";
import carTable from "./components/carTable.vue";
import { queryStatisticsPage,queryDetailsPage } from "@/api/lib/gps-api.js";

import { addExcelExport } from "@/api/lib/refreshAlarm.js";
import { getCurent, formatDate } from "@/common/utils/index";

export default {
  name: "mileageStatistics",
  components: {
    analysisForm,
    carTable,
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      form: {
        currentPage: 1,
        pageSize: 10,
        menuType: "0",
        vehicleNos: [],
        companyId: null,
        beginTime: null,
        endTime: null,
      },
      carArr: [], //   车辆明细
      comArr: [],
      total: 0,
      modelObj: null,
      exportForm: {},
      downLoadStatus: false,
    };
  },
  methods: {
    filterRate (val) {
      let rate = "";
      if (val.vehicleNum === 0 || val.vehicleNum === null) {
        rate = "0%";
      } else {
        rate = Math.round(val.useIcCardVehicleNum / val.vehicleNum * 10000) / 100 + "%";
      }
      return rate
    },
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.mileageStatistics.clientHeight;
      const formHeight = this.$refs.form.clientHeight || 64;
      this.tableHeight = wholeHeight - formHeight - 110;
    },
    //导出
    exportData (key) {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let data = {
        baseUrl: "report",
        userId: sessionStorage.getItem("userId") || localStorage.getItem('userId'),
        createTime: formatDate(new Date()),
        fileName:
          key === 1 ? `IC卡使用统计-${getCurent()}` : `IC卡使用详情-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath:
          key === 1
            ? `/icCard/statisticsExport`
            : `/icCard/detailExport`,
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },

    // 点击统计查询按钮
    onSearch (value) {
      this.form.vehicleNos = value.vehicleNos;
      this.form.companyId = value.companyId;
      this.form.beginTime = value.beginTime;
      this.form.endTime = value.endTime;
      // this.form.pageSize = 10;
      // this.form.currentPage = 1;
      this.getDataList();
    },

    // 点击统详情查询按钮
    onSubmit (value) {
      this.form.vehicleNos = value.vehicleNos;
      this.form.companyId = value.companyId;
      this.form.beginTime = value.beginTime;
      this.form.endTime = value.endTime;
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.getPageInfo();
    },

    /**切换统计|详情 */
    clickTab () {
      this.comArr = [];
      this.carArr = [];
      this.total = 0;
      this.form.currentPage = 1;
      this.form.pageSize = 10;
    },

    // 获取统计列表
    getDataList () {
      let data = { ...this.form };
      queryStatisticsPage(data).then((res) => {
        if (res.code === 1000) {
          this.comArr = res.data || [];
        } else {
          this.comArr = [];
          this.$message.error(res.msg);
        }
        this.$refs.comArrTable.doLayout();
        this.exportForm = { ...data };
      });
    },

    // 翻页
    sendInfo (info) {
      this.form.currentPage = info.currentPage;
      this.form.pageSize = info.pageSize;
      this.getPageInfo();
    },

    // 获取详情列表
    getPageInfo () {
      let data = { ...this.form };
      queryDetailsPage(data).then((res) => {
        if (res.code === 1000) {
          this.carArr = res.data.list;
          this.total = res.data.total;

        } else {
          this.carArr = [];
          this.total = 0;
          this.$message.error(res.msg);
        }
        this.$refs.comArrTable.doLayout();
        this.exportForm = { ...data };
      });
    },
  },
  created () { },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.mileage-statistics {
  height: 100%;
  width: 100%;
  ::v-deep {
    .el-tabs {
      height: 100%;
      box-sizing: border-box;
    }
  }
}
.theme-project-gps {
  .mileage-statistics {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
