<template>
  <div class="multilevel" v-if="tableData.length>0">
    <el-table :data="tableData" :height="tableHeight + 26">
      <el-table-column type="index" width="70" label="序号"></el-table-column>
      <el-table-column prop="companyName" label="所属企业" width="180"></el-table-column>
      <el-table-column prop="cph" label="车牌号"></el-table-column>
      <el-table-column prop="licensePlateColorStr" label="车牌颜色">
      </el-table-column>
      <el-table-column
        prop="vehicleTypeStr"
        label="车辆类型"
        :show-overflow-tooltip="true"
        width="180"
      >
      </el-table-column>
      <el-table-column
        v-for="(obj, key, index) in tableData[0].map"
        :key="index"
        :label="key"
        :show-overflow-tooltip="true"
      >
        <!-- <el-table-column label="启用IC卡次数" width="160"> -->
          <template slot-scope="scope">{{
            tableData[scope.$index].map[key]
          }}</template>
        <!-- </el-table-column> -->
      </el-table-column>
      <el-table-column prop="total" label="总计"></el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageObj.pageSize"
        :current-page="pageObj.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { queryDictsByCodes } from "@/api/lib/api.js";
import { formatDict } from "@/common/utils/index";
export default {
  props: {
    height: {
      type: Number,
    },
    tableData: {
      type: Array,
    },
    total: {
      type: Number,
    },
  },
  watch: {
    height(val, oldVal) {
      setTimeout(() => {
        this.tableHeight = val;
      }, 200);
    },
  },

  data() {
    return {
      pageObj: {
        currentPage: 1,
        pageSize: 10,
      },
      colorObj: {},
      typeObj: {},
      tableHeight: 0,
    };
  },

  methods: {
    handleSizeChange(val) {
      this.pageObj.pageSize = val;
      this.$emit("sendInfo", this.pageObj);
    },
    handleCurrentChange(val) {
      this.pageObj.currentPage = val;
      this.$emit("sendInfo", this.pageObj);
    },
    reset() {
      this.pageObj.currentPage = 1;
      this.pageObj.pageSize = 10;
    },
    //获取字典值
    getDicts() {
      queryDictsByCodes({ parentCodes: "CPYS,VETY" }).then((res) => {
        if (res.code === 1000) {
          this.colorObj = formatDict(res.data.CPYS);
          this.typeObj = formatDict(res.data.VETY);
        }
      });
    },
  },
  created() {
    this.getDicts();
  },
};
</script>

<style></style>
