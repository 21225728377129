var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "mileageStatistics", staticClass: "mileage-statistics" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.clickTab },
          model: {
            value: _vm.form.menuType,
            callback: function($$v) {
              _vm.$set(_vm.form, "menuType", $$v)
            },
            expression: "form.menuType"
          }
        },
        [
          _c("el-tab-pane", { attrs: { label: "统计", name: "0" } }, [
            _c(
              "div",
              { ref: "form", staticClass: "form-area" },
              [
                _c(
                  "analysis-form",
                  {
                    attrs: {
                      formatDay: true,
                      showCar: false,
                      requireCompany: true
                    },
                    on: { getFormVal: _vm.onSearch }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "small",
                          disabled: _vm.comArr.length === 0,
                          loading: _vm.downLoadStatus
                        },
                        on: {
                          click: function($event) {
                            return _vm.exportData(1)
                          }
                        }
                      },
                      [_vm._v("导出")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-table" },
              [
                _c(
                  "el-table",
                  {
                    ref: "comArrTable",
                    attrs: {
                      data: _vm.comArr,
                      height: _vm.tableHeight,
                      stripe: ""
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "index", label: "序号" }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "statisticsDate", label: "日期" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  scope.row.statisticsDate.substring(0, 10)
                                )
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "companyName", label: "所属企业" }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "vehicleNum", label: "已安装ADAS车辆数" }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "useIcCardVehicleNum",
                        label: "启用IC卡车辆数"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "completeRate", label: "IC卡使用率" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [_vm._v(_vm._s(_vm.filterRate(scope.row)))]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "el-tab-pane",
            { attrs: { label: "详情", name: "1" } },
            [
              _c(
                "div",
                { staticClass: "form-area" },
                [
                  _c(
                    "analysis-form",
                    {
                      attrs: { showMultDay: true, isAllVehicle: true },
                      on: { getFormVal: _vm.onSubmit }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            disabled: _vm.carArr.length === 0
                          },
                          on: {
                            click: function($event) {
                              return _vm.exportData(2)
                            }
                          }
                        },
                        [_vm._v("导出")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("car-table", {
                ref: "pagination2",
                attrs: {
                  height: _vm.tableHeight - 30,
                  total: _vm.total,
                  tableData: _vm.carArr
                },
                on: { sendInfo: _vm.sendInfo }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }